.icon_option {
  padding:0 10px;
  /* width: 100px; */
  display: flex;
  justify-content: start;
}
.container_home {
  background-color: #e5e5e5;
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 0;
    overflow: scroll;
    padding: 0;
  }
}
