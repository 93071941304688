.slick-dots {
    top: 93%;
    left: center;
}

.slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
}

.slick-dots.slick-thumb li {
    width: 5px;
    height: 5px;
    margin: 0 3px;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: #636262;
}

.slick-dots.slick-thumb li.slick-active {
    background-color: #f26522;
    width: 24px;
}
