.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.contend_view {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: medium;
}
.logo_VNP {
  display: flex;
  justify-content: center;
  align-items: center;
}
